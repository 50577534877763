const BIODIGITAL_DISPLAY = {
    "audio":true,
    "fullscreen":true,
    "help":true,"nav":true,
    "cameraMode":true,
    "center":true,
    "zoomIn":true,
    "zoomOut":true,
    "info":false,
    "playPause":true,
    "scrubber":true,
    "scrubber.chapters":true,
    "tools":true,
    "tools-display":true,
    "tour":true,
    "tour.chapters":true,
    "objectTree":true,
    "anatomyLabels":true,
    "share":false,
    "dissect":false,
    "xray":false,
    "annotations":false
};

export default BIODIGITAL_DISPLAY;