import React, { useState, useEffect, useContext } from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import BioDigitalHuman from '../BioDigitalHuman';
import Grid from '@material-ui/core/Grid';
import Drawer from "@material-ui/core/Drawer";
import EditorToolbar from './EditorToolbar';
import NotesComposer from './NotesComposer';
import { FirebaseContext } from '../../auth/firebase/firebaseModule';
import FormDialog from '../FormDialog';
import NewWindow from 'react-new-window'
import { withOrientationChange } from 'react-device-detect';
import Note from '../../models/note';
import Action from '../../models/action'
import NotesViewer from "./NotesViewer";
import Loader from "../Loader";
import ModalActionEdit from "./ModalActionEdit";
import ModalNoteSetProperties from "./ModalNoteSetProperties";
import { SCENE_IDS } from "../../config";
import { useNotes, _compare, isMobile, isViewMode } from '../../utils';

const Editor = ({
    classes,
    match,
    location,
    isPortrait,
    setNavBarStatusText
}) =>  {
    const firebase = useContext(FirebaseContext);
    const noteSetId = match.params.noteId;

    const [ human, setHuman ] = useState(null);
    const [ noteSetRef, setNoteSetRef ] = useState(null);
    const [ openNoteSetProperties, setOpenNoteSetProperties] = useState(noteSetId ? false : true);

    const [
        notes,
        setNotes,
        actions,
        setActions,
        allActions,
        setAllActions,
        loading,
        noteSetProperties,
        setNoteSetProperties
    ] = useNotes(noteSetId);

    const [ currentNoteIndex, setCurrentNoteIndex ] = useState(0);
    const [ currentActionIndex, setCurrentActionIndex ] = useState(0);
    const [ humanReady, setHumanReady] = useState(false);
    const [ notesLoaded, setNotesLoaded ] = useState(false);

    const [ openCustomModelDialog, setOpenCustomModelDialog ] = useState(false);
    const [ actionEditState, setActionEditState] = useState({open: false, actionId: null});

    const [ annotationsOn, setAnnotationsOn] = useState(false);
    const [ deleteAnnotationMode, setDeleteAnnotationMode] = useState(false);
    const [ annotationWorldPosition, setAnnotationWorldPosition ] = useState([]);
    const [ openAnnotationDialog, setOpenAnnotationDialog ] = useState(false);
    const [ appStatus, setAppStatus ] = useState({'isSaving': false, 'isLoading': false, 'message': ""});
    const [ windowedMode, setWindowedMode ] = useState(false);
    const [ unsavedActionIds, setUnsavedActionIds ] = useState({});
    const [ deletedNotes, setDeletedNotes] = useState([]);
    const [ mobileViewerOpen, setMobileViewerOpen ] = useState(true);

    if (!noteSetRef) {
        setNoteSetRef(firebase.getNoteSetRef(noteSetId));
    }

    const currentNote = notes[currentNoteIndex];

    useEffect(() =>{
        return () => {
            setNavBarStatusText(null);
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            if (isViewMode) {
                sortActions();
            }

            setNavBarStatusText(noteSetProperties.title);
        }
    }, [loading]);

    const updateCurrentNote = (key, value) => {
        let noteToUpdate = {...notes[currentNoteIndex]};
        noteToUpdate[key] = value;
        setNotes([...notes.slice(0, currentNoteIndex), noteToUpdate, ...notes.slice(currentNoteIndex + 1)]);
    };

    const updateActionForNote = (actionId, key, value) => {
        let _actions = JSON.parse(JSON.stringify(actions));
        let _actionsForNote = _actions[currentNote.id];
        let updateIndex = _actionsForNote.findIndex(action => action.id === actionId);
        let _updateAction = _actionsForNote[updateIndex];

        _updateAction[key] = value;

        setActions(_actions);

        let _unsavedActionIds = {...unsavedActionIds};
        _unsavedActionIds[actionId] = true;

        setUnsavedActionIds(_unsavedActionIds);
    };

    const changeNote = async (direction, callback) => {
        await direction === 'next' ?
            currentNoteIndex <= notes.length - 2 && setCurrentNoteIndex(prevIndex => prevIndex + 1) :
            currentNoteIndex >= 1 && setCurrentNoteIndex(prevIndex => prevIndex - 1);

        setCurrentActionIndex(0);

        if (callback) {
            callback();
        }
    };

    const addNote = () => {
        setCurrentNoteIndex(notes.length);
        setCurrentActionIndex(0);
        setNotes([...notes, {...new Note({id: firebase.getNotesRef().id, order: notes.length})}]);
    };

    const deleteNote = () => {
        if (currentNoteIndex > 0) {

            deletedNotes.push(notes[currentNoteIndex]);

            setCurrentNoteIndex(currentNoteIndex - 1);

            let newNotes = [...notes];
            newNotes.splice(currentNoteIndex, 1);
            setNotes(newNotes);
        }
    };

    const saveNotes = () => {
        setAppStatus({
            ...appStatus,
            'isSaving': true,
            'message': "Saving notes, please wait...",
        });

        let batch = firebase.firestore.batch();
        let noteSetMeta = {};

        noteSetMeta.title = noteSetProperties.title;
        noteSetMeta.system = noteSetProperties.system;
        noteSetMeta.region = noteSetProperties.region;
        noteSetMeta.structure = noteSetProperties.structure;
        noteSetMeta.tags = noteSetProperties.tags;
        noteSetMeta.uid = firebase.auth.currentUser.uid;
        noteSetMeta.last_modified = firebase.app.firestore.Timestamp.now();

        batch.set(noteSetRef, noteSetMeta);

        notes.forEach((note) => {
            batch.set(noteSetRef.collection('notes').doc(`${note.id}`), note);

            if (actions[note.id]) {
                let noteActions = actions[note.id];

                noteActions.forEach((action) => {
                    if (unsavedActionIds[action.id]){
                        noteSetRef
                            .collection('notes')
                            .doc(`${note.id}`)
                            .collection('actions')
                            .doc(`${action.id}`)
                            .set(action);
                    }
                });
            }

            _cleanupDeletedActions(note);
        });

        for (let note of deletedNotes){
            batch.delete(noteSetRef.collection('notes').doc(note.id))
        }

        batch.commit()
            .then(() => {
                setUnsavedActionIds({});

                setAppStatus({
                    ...appStatus,
                    'isSaving': false,
                    'message': `Notes saved`,
                });

                clearStatus()
            })
            .catch(error => {
                setAppStatus({
                    ...appStatus,
                    'isSaving': false,
                    'message': "Unable to save notes",
                });

                clearStatus();
            });

        const clearStatus = () => {
            setTimeout(() => {
                setAppStatus({
                    ...appStatus,
                    'message': ``,
                });
            }, 2000)
        }
    };

    const changeAction = (direction, actionId) => {
        let noteActions = actions[notes[currentNoteIndex].id];

        if (!noteActions) return;
        if (!humanReady) return;

        let currentAction;

        if (direction === 'next') {
            if (currentActionIndex + 1 >= noteActions.length ) return;

            currentAction = noteActions[currentActionIndex + 1];
            _setAction(currentAction);
            setCurrentActionIndex(currentActionIndex + 1);

        } else if (direction === 'previous') {
            if (currentActionIndex === 0  ) return;

            currentAction = noteActions[currentActionIndex - 1];
            _setAction(currentAction);
            setCurrentActionIndex(currentActionIndex - 1);
        } else if (actionId){
            if (allActions[actionId]){
                currentAction = allActions[actionId];
                _setAction(currentAction);
                setCurrentActionIndex(currentAction.order);
            }
        }
    };

    const _setAction = (currentAction) => {
        if (currentAction.scene) {
            human.send("scene.capture", scene => {
                if (scene.storyId !== currentAction.scene) {
                    human.send("scene.load", currentAction.scene, () => {
                        restoreScene();
                    });
                } else {
                    restoreScene();
                }
            })
        } else {
            restoreScene();
        }

        function restoreScene () {
            human.send("scene.showObjects", currentAction.objectsShown);

            let camera = currentAction.camera;
            camera.animate = true;
            camera.animationStyle = "around";

            human.send("camera.set", camera);
            human.send("scene.selectObjects", _deselectAllObjects(currentAction.objectsShown));
            human.send("scene.selectObjects", currentAction.objectsSelected);

            human.send("annotations.info", annotations => {
                if (Object.keys(annotations).length) {
                    for (let annotation in annotations) {
                        human.send("annotations.destroy", annotation)
                    }
                }

                if (currentAction.labels) {
                    if (currentAction.labels.length > 0) {
                        for (let annotation of currentAction.labels){
                            human.send("annotations.create", {
                                objectId: annotation.objectId,
                                annotationId: annotation.id,
                                title: annotation.title,
                                position: annotation.pos,
                                labelOffset: annotation.offset
                            })
                        }
                    }
                }
            });
        }
    };

    const updateNoteSetProperties = (properties) => {
        let _properties = { ...noteSetProperties };

        if (properties.title) {
            _properties.title = properties.title;
            setNavBarStatusText(properties.title);
        }

        if (properties.system) _properties.system = properties.system;
        if (properties.region) _properties.region = properties.region;
        if (properties.structure) _properties.structure = properties.structure;
        if (properties.tags) _properties.tags = properties.tags;

        setNoteSetProperties(_properties);
    };

    const sortActions = () => {
        const newActions = {...actions};
        let sortedActions = {};
        for (let noteId in newActions) {
            let actionsForNote = newActions[noteId];
            actionsForNote.sort(_compare);
            sortedActions[noteId] = actionsForNote;
        }
        setActions(sortedActions);
    };

    const syncActions = () => {
        const newActions = {...actions};

        if (!newActions[notes[currentNoteIndex].id]) return;

        let actionElements = document.getElementsByClassName('action-item');

        let noteActions = [];

        for (let i = 0; i < actionElements.length ; i++){
            let foundActionForElement;
            let actionEl = actionElements[i];
            for (let action of newActions[notes[currentNoteIndex].id]) {
                if (action.id === actionEl.getAttribute('data-key')){
                    foundActionForElement = true;
                    action.order = i;
                    noteActions.push(action);
                    break;
                }
            }

            if (!foundActionForElement){
                let actionId = actionEl.getAttribute('data-key');
                let cachedAction = allActions[actionId];

                if (cachedAction) {
                    cachedAction.order = i;
                    noteActions.push(cachedAction);
                }
            }
        }

        noteActions.sort(_compare);
        newActions[notes[currentNoteIndex].id] = noteActions;
        setActions(newActions);

        if (currentActionIndex > noteActions.length) {
            setCurrentActionIndex(noteActions.length - 1);
        }
    };

    const addAction = (title, callback) => {
        if (!humanReady) { return; }

        human.send("scene.capture", sceneState => {
            human.send("camera.info", camera => {
                let actionData = {
                    ...new Action({
                        id: firebase.getUniqueFirestoreId(),
                        noteId: notes[currentNoteIndex].id,
                        noteSetId: noteSetRef.id,
                        title: title || `Action ${actions[notes[currentNoteIndex].id] ? actions[notes[currentNoteIndex].id].length + 1 : 1}`,
                        labels: sceneState.labels,
                        modes: sceneState.modes,
                        camera: camera,
                        scene: notes[currentNoteIndex].scene
                    })
                };

                actionData.additionalSceneData = sceneState;

                for (let objectKey of sceneState.objects) {

                    objectKey.hidden === false ? actionData.objectsShown[objectKey.objectId] = true : actionData.objectsShown[objectKey.objectId] = false;

                    if (objectKey.selected) actionData.objectsSelected[objectKey.objectId] = true;
                }

                delete actionData.additionalSceneData.objects;
                delete actionData.additionalSceneData.labels;

                const newActions = {...actions};

                if (newActions[notes[currentNoteIndex].id]){
                    newActions[notes[currentNoteIndex].id].push(actionData);
                } else {
                    let actionsForNote = [];
                    actionsForNote.push(actionData);
                    newActions[notes[currentNoteIndex].id] = actionsForNote;
                }

                const unsavedActionData = {...actionData};
                const _unsavedActionIds = {...unsavedActionIds};

                _unsavedActionIds[unsavedActionData.id] = true;
                setUnsavedActionIds(_unsavedActionIds);

                if (callback) {
                    callback(actionData.id);
                }

                setActions(newActions);
                allActions[actionData.id] = actionData;

                setAllActions(prev => {
                    let newAllActions = {...prev};

                    newAllActions[actionData.id] = actionData;

                    return newAllActions;
                });
            })
        });
    };

    const changeScene = (sceneId, callback) => {
        updateCurrentNote('scene', sceneId);
    };

    const storeAnnotationWorldPosition = (canvasPosition) => {
        human.send('ui.getWorldPosition', canvasPosition, worldPosition => {
            if (!worldPosition) {
                return;
            }

            const worldPositionArray = [worldPosition.x, worldPosition.y, worldPosition.z];

            setAnnotationWorldPosition(worldPositionArray);
            setOpenAnnotationDialog(true);
        })
    };

    const createAnnotation = (title) => {
        human.send('annotations.create', {
            title: title,
            position: annotationWorldPosition
        });
    };

    const _deselectAllObjects = (objects) => {
        let _objects = {};

        for (let structure in objects) {
            _objects[structure] = false;
        }

        return _objects;
    }

    const _cleanupDeletedActions = (note) => {

        let actionsRef = noteSetRef
            .collection('notes')
            .doc(`${note.id}`)
            .collection('actions');

        actionsRef
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(action => {
                    let actionFound;

                    for (let _action of actions[note.id]) {
                        if (_action.id === action.id) actionFound = true;
                    }

                    if (!actionFound) {
                        actionsRef
                            .doc(`${action.id}`)
                            .delete()
                            .then(() => { })
                            .catch(error => {
                                console.error("Error removing action: ", error);
                            });
                    }
                });
            })
            .catch(error => {
                console.error("Error getting actions: ", error);
            });
    }

    const _getActionForNoteById = (actionId, noteId) => {
        if (!actionId || !noteId) {
            return;
        }

        for (let action of actions[noteId]) {
            if (action.id === actionId) {
                return action;
            }
        }
    };

    const renderViewer = () => {
        if (isMobile) {
            return (
                <Drawer
                    classes={{
                        paper: classes.notesViewerPanel,
                        paperAnchorRight: classes.notesViewerAnchorRight
                    }}
                    anchor={isPortrait ? "bottom" : "right"} open={mobileViewerOpen} onClose={() => setMobileViewerOpen(false)}>
                    <NotesViewer
                        currentNote={currentNote}
                        changeAction={changeAction}
                        changeNote={changeNote}
                        numNotes={notes.length}
                        isPortrait={isPortrait}
                    />
                </Drawer>
            )

        } else {
            return (
                <NotesViewer
                    currentNote={currentNote}
                    changeAction={changeAction}
                    changeNote={changeNote}
                    numNotes={notes.length}
                />
            )
        }
    };

    if (loading) {
        return <Loader loading={loading}/>
    }

    return (
        <div className={classes.root}>
            <ModalActionEdit
                open={actionEditState.open}
                actionEditState={actionEditState}
                setActionEditState={setActionEditState}
                updateActionForNote={updateActionForNote}
                action={_getActionForNoteById(actionEditState.actionId, currentNote.id)}/>

            <FormDialog
                open={openAnnotationDialog}
                title="Create Annotation"
                content=""
                label="Title"
                textValue=""
                onConfirm={(title) => {
                    setOpenAnnotationDialog(false);
                    createAnnotation(title);
                }}
                onCancel={()=> setOpenAnnotationDialog(false)}
                onClose={() => setOpenNoteSetProperties(false)}/>

            <ModalNoteSetProperties
                open={openNoteSetProperties}
                noteSetProperties={noteSetProperties}
                setOpenNoteSetProperties={setOpenNoteSetProperties}
                updateNoteSetProperties={updateNoteSetProperties}
                noteSetId={noteSetRef ? noteSetRef.id : "not set yet"}
            />

            <FormDialog
                open={openCustomModelDialog}
                title="Load Custom Model"
                content={`Model: ${currentNote.scene || SCENE_IDS.DEV}`} //Required format example: production/maleAdult/male_region_upper_limb_07
                label="Scene ID"
                textValue={currentNote.scene || SCENE_IDS.DEV}
                onConfirm={(model) => {
                    setOpenCustomModelDialog(false);
                    updateCurrentNote("scene", model)
                }}
                onCancel={() => {
                    setOpenCustomModelDialog(false);
                }}/>

            <EditorToolbar
                changeAction={changeAction}
                changeScene={changeScene}
                editTitle={setOpenNoteSetProperties}
                setCustomModel={setOpenCustomModelDialog}
                currentAction={actions[currentNote.id] ? currentActionIndex + 1 : null}
                actions={actions[currentNote.id] ? actions[currentNote.id] : []}
                annotationsOn={annotationsOn}
                setAnnotationsOn={setAnnotationsOn}
                deleteAnnotationMode={deleteAnnotationMode}
                setDeleteAnnotationMode={setDeleteAnnotationMode}
                setMobileViewerOpen={setMobileViewerOpen}
                setActionEditState={setActionEditState}/>

            <Grid container spacing={2}>
                <Grid item xs={12} md={isMobile ? 12 : 8}>
                    <BioDigitalHuman
                        notesLoaded={notesLoaded}
                        human={human}
                        setHuman={setHuman}
                        setHumanReady={setHumanReady}
                        changeAction={changeAction}
                        changeNote={changeNote}
                        currentScene={currentNote.scene}
                        noteOrder={currentNote.order + 1}
                        numNotes={notes.length}
                        currentAction={actions[currentNote.id] ? currentActionIndex + 1 : 0}
                        numActions={actions[currentNote.id] ? actions[currentNote.id].length : 0}
                        annotationsOn={annotationsOn}
                        storeAnnotationWorldPosition={storeAnnotationWorldPosition}/>
                </Grid>

                <Grid item xs={12} md={isMobile ? 12 : 4}>
                    {windowedMode &&
                        <NewWindow onUnload={() => setWindowedMode(false)} title={`3D Anatomy: ${noteSetProperties.title}`}>
                            <NotesViewer
                                currentNote={currentNote}
                                changeAction={changeAction}
                                changeNote={changeNote}
                                numNotes={notes.length}/>
                        </NewWindow>}

                    {isViewMode
                        ? renderViewer()
                        : <NotesComposer
                            humanReady={humanReady}
                            currentNote={currentNote}
                            numNotes={notes.length}
                            updateCurrentNote={updateCurrentNote}
                            updateActionsForNote={syncActions}
                            saveNotes={saveNotes}
                            addNote={addNote}
                            deleteNote={deleteNote}
                            changeNote={changeNote}
                            addAction={addAction}
                            changeAction={changeAction}
                            appStatus={appStatus}
                            setAppStatus={setAppStatus}
                            setWindowedMode={setWindowedMode}/>}
                </Grid>
            </Grid>
        </div>
    );
};

Editor.propTypes = {
    classes: PropTypes.object.isRequired,
    setNavBarStatusText: PropTypes.func
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: 10,

    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    myHuman: {
        width: '100%',
        height: window.innerHeight * 0.8
    },
    notesViewerPanel: {
        background: "none"
    },
    notesViewerAnchorRight: {
        width: "50%"
    }
});

export default withOrientationChange(withStyles(styles)(Editor));
