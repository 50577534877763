import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from '@material-ui/core/Paper';
import NotesControls from "./NotesControls";
import PropTypes from "prop-types";
import ReactQuill, { Quill } from 'react-quill';
import QuillToolbar from './QuillToolbar'
import NotesChip from "./NotesChip";
import StatusBar from "./StatusBar";

import 'react-quill/dist/quill.snow.css';
import './NotesComposer.css';

const styles = theme => ({
    root: {
        borderRadius: 2,
        marginTop: 5
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    chip: {
        margin: theme.spacing(1),
        height: 'auto',
        fontSize: '0.65rem'
    },
    quill: {
        margin: theme.spacing(1)
    }
});

const notesChipStyle = {
    margin: '8px',
    height: 'auto',
    fontSize: '0.65rem'
};

const NotesComposer = ({
    classes,
    appStatus,
    setAppStatus,
    currentNote,
    updateCurrentNote,
    numNotes,
    saveNotes,
    addAction,
    addNote,
    deleteNote,
    changeNote,
    humanReady,
    changeAction,
    updateActionsForNote,
    windowedMode,
    setWindowedMode
}) => {

    const [ content, setContent ] = useState(currentNote.content);
    const [ selectedText, setSelectedText ] = useState("");
    const [ quillRef, setQuillRef] = useState(null);
    const [ disableAddActionFormat, setDisableAddActionFormat] = useState(true);
    const [ disableRemoveActionFormat, setDisableRemoveActionFormat] = useState(true);

    let reactQuillRef = null;

    // componentDidMount
    useEffect(() => {
        registerFormats();
    }, []);

    useEffect(() => setContent(currentNote.content), [currentNote.content]);

    // componentDidUpdate
    useEffect(() => {
        if (!humanReady) return;
        setClickListenersOnActionText();
    });

    const setClickListenersOnActionText = (remove) => {

        let actionElements = document.getElementsByClassName('action-item');

        for (let actionElement of actionElements) {
            if (actionElement.getAttribute('data-action-listener') === 'true' ) {
                continue;
            }
            actionElement.setAttribute('data-action-listener', 'true');
            actionElement.addEventListener('click', goToAction);
        }

        function goToAction(event) {
            let actionId = event.target.getAttribute('data-key');
            changeAction(null, actionId);
        }

    };

    const registerFormats = () => {

        // Ensure React-Quill references is available:
        if (typeof reactQuillRef.getEditor !== 'function') return;

        // Skip if Quill reference is defined:
        if (quillRef != null) return;

        const _quillRef = reactQuillRef.getEditor(); // could still be null

        if (_quillRef != null) {
            setQuillRef(_quillRef);
        }
    };

    const updateNoteContent = value => {
        setContent(value);
        updateCurrentNote('content', value);
    };

    const addActionFormat = () => {

        let range = quillRef.getSelection();

        if (range.length < 1) return console.info("Cannot add action - no text selected");

        addAction(selectedText, actionId => {
            if (range) {
                quillRef.format('action', {key: actionId});
                quillRef.insertText(range.index + range.length, " ");
                quillRef.removeFormat(range.index + range.length, 1);
                quillRef.setSelection(range.index + range.length + 1, 0);
            }
        });
    };

    const removeActionFormat = () => {
        let range = quillRef.getSelection();

        quillRef.removeFormat(range.index, range.length);
    };

    return (
        <Paper className={classes.root} elevation={0}>
            <NotesChip order={currentNote.order + 1} numNotes={numNotes} style={notesChipStyle}/>
            <form className={classes.container} noValidate autoComplete="off">
                <TextField
                    id="standard-name"
                    label="Note Title"
                    className={classes.textField}
                    value={currentNote.title}
                    onChange={event => updateCurrentNote(event.target.name, event.target.value)}
                    margin="normal"
                    name="title"
                    variant="outlined"
                />
            </form>

            <QuillToolbar/>

            <ReactQuill
                ref={el => reactQuillRef = el}
                className={classes.quill}
                value={content}
                modules={NotesComposer.modules}
                formats={NotesComposer.formats}
                onChange={content => {
                    updateNoteContent(content);
                    updateActionsForNote();
                }}
                onChangeSelection={(range, source, editor) => {
                    if (range){
                        let selected = editor.getText(range.index, range.length);

                        setSelectedText(selected);

                        // Enable action formatting buttons (remove/add)
                        if (range.length > 0){
                            if (!quillRef.getFormat().action) setDisableAddActionFormat(false);
                            if (quillRef.getFormat().action) setDisableRemoveActionFormat(false);
                        } else {
                            setDisableAddActionFormat(true);
                            setDisableRemoveActionFormat(true);
                        }
                    }
                }}/>

            <NotesControls
                saveNotes={saveNotes}
                addNote={addNote}
                deleteNote={deleteNote}
                changeNote={direction => changeNote(direction, () => quillRef.history.clear())}
                addAction={addActionFormat}
                removeAction={removeActionFormat}
                disableAddActionFormat={disableAddActionFormat}
                disableRemoveActionFormat={disableRemoveActionFormat}
                setWindowedMode={setWindowedMode}/>

            <StatusBar appStatus={appStatus} setAppStatus={setAppStatus}/>
        </Paper>
    );
};

let Inline = Quill.import('blots/inline');

class ActionBlot extends Inline {

    static create(value) {
        let node = super.create();
        node.setAttribute('data-key', value.key);
        node.classList.add("action-item");
        return node;
    }

    static formats(node) {
        return {
            key: node.getAttribute('data-key')
        };
    }
}

ActionBlot.blotName = 'action';
ActionBlot.className = 'action-item';
Quill.register('formats/action', ActionBlot);


NotesComposer.modules = {
    toolbar: {
        container: "#toolbar",
        handlers: {}
    }
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
NotesComposer.formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "formats/action",
    "action"
];

NotesComposer.propTypes = {
    currentNote: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
        actions: PropTypes.array,
        order: PropTypes.number,
        id: PropTypes.string
    }),
    numNotes: PropTypes.number,
    updateCurrentNote: PropTypes.func,
    saveNotes: PropTypes.func,
    addNote: PropTypes.func,
    changeNote: PropTypes.func,
    addAction: PropTypes.func,
};

export default withStyles(styles)(NotesComposer);
