import React, { useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ActionsChip from "./Editor/ActionsChip";
import NotesChip from "./Editor/NotesChip";
import AnnotationsCanvas from "./Editor/AnnotationsCanvas";
import Script from 'react-load-script';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { withOrientationChange, isFullscreen as detectFullscreen } from 'react-device-detect';
import { isViewMode } from '../utils';
import { API_KEY, API_SCRIPT_URL, BIODIGITAL_DISPLAY } from '../config';

const styles = theme => ({
    myHuman: {
        width: '100%',
        height: window.innerHeight - 135
    },
    fullscreenNav: {
        position: 'absolute',
        right: 0,
        bottom:0,
        zIndex: 10
    },
    navBtn: {
        color: "rgba(0, 0, 0, 0.02)"
    },
    chipsContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: "none"
    },
    modelContainer: {
        position: 'relative'
    },
    annotationsCanvas: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    button: {
        position: 'absolute',
        zIndex: 1,
        margin: 2,
    }
});

const fullScreenStyle = {
    width: '100%',
    height: '100%'
};

const chipStyle = {
    margin: '8px',
    height: 'auto',
    fontSize: '0.65rem'
};

const BioDigitalHuman = ({
    classes,
    human,
    storeAnnotationWorldPosition,
    annotationsOn,
    currentScene,
    changeAction,
    changeNote,
    setHuman,
    setHumanReady,
    noteOrder,
    numNotes,
    currentAction,
    numActions
}) => {
    useEffect(() => {
        if (human) {
            setHotSpotPickedListener();
        }
    }, [currentScene]);

    useEffect(() => setHotSpotPickedListener(), [human]);

    const handleScriptLoad = () => {
        let human = new window.HumanAPI("myHuman");

        setHuman(human);

        human.on('human.ready', () => {
            setHumanReady(true);
        });
    };

    const setHotSpotPickedListener = () => {
        if (!human) return;

        setTimeout(() => {
            human.on('hotspots.picked', hotspot => {
                human.send('hotspots.destroy', hotspot.id)
            });
        }, 2000);
    };

    const calcBiodigitalModelHeight = () => {
        let windowHeight = window.innerHeight;
        let style = {
            width: '100%',
            height: windowHeight
        };

        return detectFullscreen ? fullScreenStyle : style;
    };

    const handle = useFullScreenHandle();
    const isFullscreen = handle.active;
    const toggleFullScreen = () => {
        handle.enter();
    };

    useLayoutEffect(() => {
        if (!human) return;

        if (isFullscreen) {
            human.send('ui.setDisplay', { 'all': false });
        } else {
            human.send('ui.setDisplay', BIODIGITAL_DISPLAY);
        }
    });

    return (
        <div className={classes.modelContainer}>
            {!isViewMode &&
                <IconButton color="primary" className={classes.button} aria-label="Edit" onClick={toggleFullScreen}>
                    <FullscreenIcon/>
                </IconButton>}

            <FullScreen handle={handle}>
                {detectFullscreen &&
                    <>
                        <div className={classes.chipsContainer}>
                            <NotesChip order={noteOrder} numNotes={numNotes} style={chipStyle}/>
                            <ActionsChip currentAction={currentAction} numActions={numActions} style={chipStyle}/>
                        </div>
                        <div className={classes.fullscreenNav}>
                            <Icon className={classes.navBtn} onClick={() => changeNote('next')}>keyboard_arrow_up</Icon>
                            <Icon className={classes.navBtn} onClick={() => changeNote('previous')}>keyboard_arrow_down</Icon>
                            <Icon className={classes.navBtn} onClick={() => changeAction('previous')}>navigate_before</Icon>
                            <Icon className={classes.navBtn} onClick={() => changeAction('next')}>navigate_next</Icon>
                        </div>
                    </>}

                <iframe id="myHuman"
                    className={classes.myHuman}
                    style={calcBiodigitalModelHeight()}
                    src={`https://human.biodigital.com/widget/?m=${currentScene}.json&ui-info=false&ui-fullscreen=true&ui-tools=true&ui-annotations=true&ui-object-tree=true=true&dk=${API_KEY}`}>
                </iframe>

                <AnnotationsCanvas
                    className={classes.annotationsCanvas}
                    annotationsOn={annotationsOn}
                    storeAnnotationWorldPosition={storeAnnotationWorldPosition}>
                </AnnotationsCanvas>

                {isFullscreen && <div className="fullscreen-logo"/>}
            </FullScreen>

            <Script url={API_SCRIPT_URL} onLoad={handleScriptLoad}/>
        </div>
    );
};

BioDigitalHuman.propTypes = {
    classes: PropTypes.object.isRequired,
    setHuman: PropTypes.func.isRequired,
    setHumanReady: PropTypes.func.isRequired,
    changeAction: PropTypes.func.isRequired,
    changeNote: PropTypes.func.isRequired,
    annotationsOn: PropTypes.bool.isRequired,
};

export default withOrientationChange(withStyles(styles)(BioDigitalHuman));
