import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RemoveIcon from '@material-ui/icons/Remove';
import { FirebaseContext } from "../../auth/firebase/firebaseModule";

const NotesControls = ({
    classes,
    disableRemoveActionFormat,
    disableAddActionFormat,
    addAction,
    removeAction,
    addNote,
    changeNote,
    deleteNote,
    saveNotes,
    setWindowedMode,
    isPopout
}) => {
    const firebase = useContext(FirebaseContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const email = sessionStorage.getItem('email');

        firebase.firestore.collection('admins')
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        setIsAdmin(true);
                    }
                });
            });
    }, [])

    if (isPopout) {
        return (
            <>
                <IconButton color="primary" className={classes.button} aria-label="Previous Note" onClick={() => changeNote('previous')}>
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Next Note" onClick={() => changeNote('next')}>
                    <ChevronRightIcon/>
                </IconButton>
            </>
        )
    } else {
        return (
            <>
                <IconButton disabled={disableAddActionFormat} color="primary" className={classes.button} aria-label="Add Action" onClick={addAction}>
                    <AddIcon/>
                </IconButton>
                <IconButton disabled={disableRemoveActionFormat} color="secondary" className={classes.button} aria-label="Remove Action" onClick={removeAction}>
                    <RemoveIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Previous Note" onClick={() => changeNote('previous')}>
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Next Note" onClick={() => changeNote('next')}>
                    <ChevronRightIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Add Note" onClick={addNote}>
                    <NoteAddIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Delete Note" onClick={deleteNote}>
                    <DeleteOutlineIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Save Note" onClick={saveNotes} disabled={!isAdmin}>
                    <SaveIcon/>
                </IconButton>
                <IconButton color="primary" className={classes.button} aria-label="Popout Notes composer" onClick={() => setWindowedMode(true)}>
                    <OpenInNewIcon/>
                </IconButton>
            </>
        );
    }
};

NotesControls.propTypes = {
    classes: PropTypes.object.isRequired,
    changeNote: PropTypes.func,
    addNote: PropTypes.func,
    deleteNote: PropTypes.func,
    saveNotes: PropTypes.func,
    addAction: PropTypes.func,
    removeAction: PropTypes.func,
    disableRemoveActionFormat: PropTypes.bool,
    disableAddActionFormat: PropTypes.bool
};

const styles = theme => ({
    button: {
        margin: '1px',
    }
});

export default withStyles(styles)(NotesControls);
