import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import FirebaseContext from "../../auth/firebase/firebaseContext";
import NoteListItem from "./NoteListItem";
import FormDialog from "../FormDialog";
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20
    }
});

const NotesList = ({ classes, match }) => {
    const firebase = useContext(FirebaseContext);

    let location = useLocation();

    const [ notes, setNotes ] = useState([]);
    const [ filteredNotes, setFilteredNotes] = useState([]);
    const [ deleteNoteSet, setDeleteNoteSet ] = useState({'id': "", 'title': '', 'openDialog': false});

    const categoryType = match.params.noteCategoryType;
    const category = match.params.noteCategory;

    const deleteNote = () => {
        firebase.deleteNoteSet(deleteNoteSet.id, () => console.log("Deleted noteset: ", deleteNoteSet.id));
    };

    useEffect(() => {
        const unsubscribe = firebase.firestore.collection('note_sets').onSnapshot(querySnapshot => {
            let limitAccess = false;
            const _notes = [];

            querySnapshot.forEach(doc => {
                let note = doc.data();
                note.id = doc.id;

                if (limitAccess){
                    if (note.dev) _notes.push(note);
                } else {
                    _notes.push(note);
                }
            });

            // Sort
            sortByTitle(_notes);

            // Filter
            if (!category || !categoryType) {
                setFilteredNotes(_notes);
            } else {
                setFilteredNotes(filterNotes(categoryType, category, _notes));
            }

            setNotes(_notes);
        });

        return () => {
            unsubscribe()
        }

    }, []);

    useEffect(() => {
        if (!category || !categoryType) {
            setFilteredNotes(notes);
        } else {
            setFilteredNotes(filterNotes(categoryType, category, notes));
        }
    }, [location]);

    const sortByTitle = notes => {
        notes.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
        });
    };

    const filterNotes = (categoryType, category, notesToFilter) => {
        let filteredNotes, _category = category.replace(/[^a-zA-Z]+/g, ' ');

        //TODO: use the url path to reverse look up the key from the routes to get the correct region/system
        if (categoryType === "region"){
            filteredNotes = notesToFilter.filter(note => {
                if (note.region) {
                    let region = note.region.replace(/[^a-zA-Z]+/g, ' ').toLowerCase();
                    return region.includes(_category) ? true : false;
                }
            });
        }

        if (categoryType === "system") {
            filteredNotes = notesToFilter.filter(note => {
                if (note.system) {
                    let system = note.system.replace(/[^a-zA-Z]+/g, ' ').toLowerCase();
                    return system.includes(_category) ? true : false;
                }
            });
        }

        return filteredNotes;
    };

    const handleSearch = searchTerm => {
        const _notes = [...notes];
        let _filteredNotes = _notes.filter(note => note.title.toLowerCase().includes(searchTerm.toLowerCase()));

        searchTerm === "" || searchTerm == null ? setFilteredNotes(notes) : setFilteredNotes(_filteredNotes);
    };
console.log({filteredNotes})
    return (
        <div className={classes.root}>
            <FormDialog
                open={deleteNoteSet.openDialog}
                title="Delete Note Set"
                content={`Confirm delete note set: ${deleteNoteSet.title}`}
                hideTextField={true}
                onConfirm={() => {
                    deleteNote();
                    setDeleteNoteSet({...deleteNoteSet, openDialog: false})
                }}
                onCancel={() => setDeleteNoteSet({...deleteNoteSet, id: '', openDialog:false})}
                onClose={() => setDeleteNoteSet({...deleteNoteSet, id: '', openDialog:false})}/>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        id="search-notes"
                        label="Search"
                        variant="outlined"
                        onChange={event => handleSearch(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            )
                        }}/>
                </Grid>

                <Grid item xs={12}>
                    {_.isEmpty(filteredNotes) && <div>No notes available</div>}

                    <List>
                        {filteredNotes.map((note, index) =>
                            <NoteListItem index={index}
                                key={note.id}
                                title={note.title}
                                deleteNote={() => setDeleteNoteSet({...deleteNoteSet, id: note.id, title: note.title, openDialog: true})}
                                viewLink={`/viewer/${note.id}`}
                                editLink={`/editor/${note.id}`}/>
                        )}
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};

export default withStyles(styles)(NotesList);
