import React, { useState } from 'react';
import withStyles from "@material-ui/core/es/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { SCENE_IDS } from '../../config'

const styles = theme => ({
    modelSelect: {
        'display': 'inline'
    }
});

const ModelSelectMenu = props => {

    const { classes, changeScene, setCustomModel } = props;

    const [ anchorEl, setAnchorEl ] = useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);

    const handleClose = (sceneId, customId) => {
        if (sceneId) changeScene(sceneId);
        if (customId) setCustomModel(true);
        setAnchorEl(null);
    };

    return (
        <div className={classes.modelSelect}>
            <Button
                aria-owns={anchorEl ? 'model-select-menu' : undefined}
                aria-haspopup="true"
                color="primary"
                onClick={handleClick}>
                Select Model
            </Button>

            <Menu
                id="model-select-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
                color="primary"
            >
                <MenuItem onClick={() => handleClose(SCENE_IDS.UPPER_LIMB)}>Upper Limb</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.LOWER_LIMB)}>Lower Limb</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.HEAD_AND_NECK)}>Head and Neck</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.THORAX)}>Thorax</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.BACK)}>Back</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.MALE_ABDOMEN)}>Male Abdomen</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.MALE_PELVIS)}>Male Pelvis</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.FEMALE_ABDOMEN)}>Female Abdomen</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.FEMALE_PELVIS)}>Female Pelvis</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.HEART)}>Heart</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.BRAIN)}>Brain</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.BREAST)}>Female Breast</MenuItem>
                <MenuItem onClick={() => handleClose(SCENE_IDS.AUDITORY)}>Auditory</MenuItem>
                <hr/>
                <MenuItem onClick={() => handleClose(null, true)}>Load custom model</MenuItem>
            </Menu>
        </div>
    )
};

ModelSelectMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    changeScene: PropTypes.func.isRequired,
    setCustomModel: PropTypes.func.isRequired
};

export default withStyles(styles)(ModelSelectMenu);
