import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ChipInput from "material-ui-chip-input";
import { CATEGORIES } from "../../config";
import { PageContext } from '../../utils';

const styles = theme => ({
    formContainer: {
        padding: 10,
        marginBottom: 10
    },
    titleContainer: {
        marginLeft: 10,
        marginBottom: 20,
        width: '100%',
        maxWidth: '400px'
    },
    chipInput: {
        width: '100%',
        maxWidth: '400px'
    }
});

const ModalNoteSetProperties = ({
    classes,
    open,
    noteSetProperties,
    setOpenNoteSetProperties,
    updateNoteSetProperties
}) => {
    const dispatch = useContext(PageContext)[1];

    const [titleText, setTitleText] = useState(noteSetProperties.title);
    const [systemVal, setSystemVal] = useState(noteSetProperties.system || "CARDIOVASCULAR");
    const [regionVal, setRegionVal] = useState(noteSetProperties.region || "THORAX");
    const [structureVal, setStructureVal] = useState(noteSetProperties.structure || "ORGAN");
    const [tagsVal, setTagsVal] = useState(noteSetProperties.tags || []);


    const handleConfirm = () => {
        let properties = {};

        properties.title = titleText;
        properties.system = systemVal;
        properties.region = regionVal;
        properties.structure = structureVal;
        properties.tags = tagsVal;

        updateNoteSetProperties(properties);
        setOpenNoteSetProperties(false);
    };

    const handleCancel = () => {
        let properties = {};
        properties.title = noteSetProperties.title || "Untitled";
        properties.system = noteSetProperties.system || systemVal;
        properties.region = noteSetProperties.region || regionVal;
        properties.structure = noteSetProperties.structure || structureVal;
        properties.tags = noteSetProperties.tags || tagsVal;

        updateNoteSetProperties(properties);
        setOpenNoteSetProperties(false);
    };

    const handleAddChip = chip => {
        let _tags = [...tagsVal];
        _tags.push(chip);
        setTagsVal(_tags);
    };

    const handleDeleteChip = (chip, index) => {
        let _tags = [...tagsVal];
        _tags.splice(index, 1);
        setTagsVal(_tags);
    };

    const generateCategory = categoryObject => {
        let categoryArray = [];

        for (let categoryId in categoryObject){
            let data = [];
            data.push(categoryId);
            data.push(categoryObject[categoryId]);
            categoryArray.push(data);
        }

        return categoryArray.map(categoryData => (
            <MenuItem key={categoryData[0]} value={categoryData[0]}>{categoryData[1]}</MenuItem>)
        );
    };

    const setNoteTitle = ({ target }) => {
        const { value } = target;

        dispatch({ type: "SET_NOTE_TITLE", payload: value });
        setTitleText(value);
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Note Set Properties</DialogTitle>

            <DialogContent>
                <FormControl className={classes.titleContainer} fullWidth>
                    <TextField
                        margin="dense"
                        id="name"
                        label="Title"
                        type="text"
                        defaultValue={titleText}
                        onChange={setNoteTitle}
                        fullWidth/>
                </FormControl>

                <div className="selects-container">
                    <FormControl className={classes.formContainer}>
                        <InputLabel htmlFor="system-select">System</InputLabel>
                        <Select
                            id="system-select"
                            value={systemVal}
                            onChange={event => setSystemVal(event.target.value)}>
                            {generateCategory(CATEGORIES.SYSTEM)}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formContainer}>
                        <InputLabel htmlFor="region-select">Region</InputLabel>
                        <Select
                            id="region-select"
                            value={regionVal}
                            onChange={event => setRegionVal(event.target.value)}>
                            {generateCategory(CATEGORIES.REGION)}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formContainer}>
                        <InputLabel htmlFor="structure-select">Structure</InputLabel>
                        <Select
                            id="structure-select"
                            value={structureVal}
                            onChange={event => setStructureVal(event.target.value)}>
                            {generateCategory(CATEGORIES.STRUCTURE)}
                        </Select>
                    </FormControl>
                </div>

                <div className="chip-container">
                    <ChipInput
                        value={tagsVal}
                        label="Tutorial Tags"
                        className={classes.chipInput}
                        placeholder="Tutorial tags (Press Tab to apply)"
                        onAdd={chip => handleAddChip(chip)}
                        onDelete={(chip, index) => handleDeleteChip(chip, index)}
                        newChipKeyCodes={[9]}/>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => handleCancel()}
                    color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => handleConfirm()}
                    color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(ModalNoteSetProperties);
