const reducer = (state, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case "SET_NOTE_TITLE":
      return {
        ...state,
        noteTitle: payload
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
