import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = theme => ({
    button: {
        margin: '1px',
    }
});

const ActionButtons = ({ classes, changeAction }) => (
    <>
        <IconButton color="primary" className={classes.button} aria-label="Previous Action" onClick={() => changeAction('previous')}>
            <ChevronLeftIcon/>
        </IconButton>
        <IconButton color="primary" className={classes.button} aria-label="Next Action" onClick={() => changeAction('next')}>
            <ChevronRightIcon/>
        </IconButton>
    </>
);

ActionButtons.propTypes = {
    classes: PropTypes.object.isRequired,
    changeAction: PropTypes.func.isRequired
};

export default withStyles(styles)(ActionButtons);
