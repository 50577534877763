import React, { useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as ROUTES from "../router/routes";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FirebaseContext } from '../auth/firebase/firebaseModule'
import { compose } from 'recompose';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

function RegisterBase (props) {

    const firebase = useContext(FirebaseContext);

    const [name, setName ] = useState('');
    const [email, setEmail ] = useState('');
    const [password, setPassword ] = useState('');
    const [error, setError] = useState('');

    function onSubmit (event) {

        event.preventDefault();

        firebase
            .createUserWithEmailAndPassword(email, password, () => {
                setTimeout(() => {
                    props.history.push(ROUTES.CREATE);
                }, 50);
            }, error => {
                console.error("Error creating user: ", error);
                setError(error);
            });

    };

    const { classes } =  props;
    const isInvalid = password === '' || name === '' || email === '';

    return (
        <main className={classes.main}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <form className={classes.form} onSubmit={onSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Full name</InputLabel>
                        <Input id="name" name="name" autoComplete="email" onChange={event => setName(event.target.value)} autoFocus />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" onChange={event => setEmail(event.target.value)} autoFocus />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input name="password" type="password" id="password" onChange={event => setPassword(event.target.value)} autoComplete="current-password" />
                    </FormControl>
                    <Button
                        disabled={isInvalid}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Register
                    </Button>
                    <div style={{marginTop: '10px'}}>
                        {error && <Typography variant='body1' color='error'>
                            {error.message}
                        </Typography>}
                    </div>
                </form>
            </Paper>
        </main>
    );
};

RegisterBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

const Register = compose(withRouter)(RegisterBase);

export default withStyles(styles)(Register);
