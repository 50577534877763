const CATEGORIES = {
    SYSTEM: {
        "MUSCULOSKELETAL": "Musculoskeletal",
        "CARDIOVASCULAR": "Cardiovascular",
        "NEUROANATOMY": "Neuroanatomy",
        "RESPIRATORY": "Respiratory",
        "DIGESTIVE": "Digestive",
        "URINARY": "Urinary",
        "REPRODUCTIVE": "Reproductive"
    },
    REGION: {
        "UPPER_LIMB": "Upper Limb",
        "LOWER_LIMB": "Lower Limb",
        "HEAD": "Head",
        "NECK": "Neck",
        "BACK": "Back",
        "THORAX": "Thorax",
        "ABDOMEN": "Abdomen",
        "PELVIS": "Pelvis",
        "NEUROANATOMY": "Neuroanatomy",
    },
    STRUCTURE: {
        "BONE": "Bone",
        "JOINT": "Joint",
        "MUSCLE": "Muscle",
        "VASCULATURE": "Vasculature",
        "ORGAN": "Organ",
        "NERVE": "Nerve",
    }
};

export default CATEGORIES;
