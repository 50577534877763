import React from 'react'
import Chip from "@material-ui/core/Chip";

const NotesChip = props => {

    const { order, numNotes, style } = props;

    return (
        <>
            <Chip label={`Note ${order}/${numNotes}`} variant="outlined" style={style} />
        </>
    )
};

export default NotesChip