import React, {useState} from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from "@material-ui/core/es/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import { isViewMode, isMobile } from '../../utils';

const styles = theme => ({
    actionList: {
        'display': 'inline'
    },
    actionItemContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%"
    },
    button: {
        margin: '0 8px'
    }
});

const ActionListMenu = ({
    classes,
    actions,
    changeAction,
    setActionEditState
}) => {
    const [anchorEl, setAnchorEl ] = useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className={classes.actionList}>
            <Button className={classes.button}
                aria-owns={anchorEl ? 'action-list-menu' : undefined}
                aria-haspopup="true"
                color="primary"
                onClick={handleClick}>
                Labels
            </Button>

            <Menu id="action-list-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                color="primary">

                {_.isEmpty(actions) && <MenuItem>No Labels</MenuItem>}

                {actions.map(action => {
                    return (
                        <MenuItem key={action.id}>
                            <div className={classes.actionItemContainer}>
                                <div className={classes.actionItemText}
                                    onClick={() => changeAction(null, action.id, actions)}>
                                    {action.title ? action.title : "Untitled Action"}
                                </div>

                                {!isViewMode && !isMobile &&
                                    <div className={classes.actionItemEdit}>
                                        <IconButton size="small" color="default"
                                            className={classes.button} aria-label="Edit"
                                            onClick={() => setActionEditState({ open: true, actionId: action.id })}>
                                            <EditIcon/>
                                        </IconButton>
                                    </div>}
                            </div>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

ActionListMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    changeAction: PropTypes.func.isRequired
};

export default withStyles(styles)(ActionListMenu);
