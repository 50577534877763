import React, { useContext } from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import ModelSelectMenu from "./ModelSelector";
import Actions from './Actions'
import { OpenInBrowser } from '@material-ui/icons'
import { isMobile } from 'react-device-detect'
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import EditIcon from '@material-ui/icons/Edit';
import { isViewMode, PageContext } from '../../utils';

const styles = theme => ({
    editorToolbar: {
        padding: 15,
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.7)',
    },
    input: {
        display: 'none',
    },
    button: {
        margin: '2px 5px',
    },
    annotateModeOnText: {
        color: "red"
    },
    deleteAnnotationModeText: {
        color: "red"
    },
    annotContainer: {
        display: 'inline',
        marginLeft: 15
    }
});

const EditorToolbar = ({
    classes,
    deleteAnnotationMode,
    setDeleteAnnotationMode,
    editTitle,
    changeAction,
    actions,
    currentAction,
    changeScene,
    setCustomModel,
    annotationsOn,
    setAnnotationsOn,
    setMobileViewerOpen,
    setActionEditState
}) => {
    const { noteTitle } = useContext(PageContext)[0];

    const setAnnotateModeText = () => {
        if (annotationsOn) return <span className={classes.annotateModeOnText}>Annotate mode is ON</span>
    };

    const setDeleteAnnotationModeText = () => {
        if (deleteAnnotationMode) return <span className={classes.deleteAnnotationModeText}>Delete Annotation Mode</span>
    };

    return (
        <div className={classes.editorToolbar}>
            <div className="note-title">{noteTitle}</div>

            {!isViewMode &&
                <IconButton color="primary" className={classes.button} aria-label="Edit" onClick={() => editTitle(true)}>
                    <EditIcon/>
                </IconButton>}

            <Actions
                actions={actions}
                changeAction={changeAction}
                currentAction={currentAction}
                numActions={actions && actions.length}
                setActionEditState={setActionEditState}/>

            {!isViewMode &&
                <ModelSelectMenu changeScene={changeScene} setCustomModel={setCustomModel}/>}

            {!isViewMode && <div className={classes.annotContainer}>
                <IconButton color="primary" aria-label="Annotate" onClick={() => setAnnotationsOn(!annotationsOn)}>
                    <AddCircleIcon/>
                </IconButton>
                {setAnnotateModeText()}

                <IconButton color="secondary" aria-label="Annotate" onClick={() => setDeleteAnnotationMode(!deleteAnnotationMode)}>
                    <RemoveCircleIcon/>
                </IconButton>
                {setDeleteAnnotationModeText()}
            </div>}

            {isMobile && isViewMode &&
                <IconButton color="primary" className={classes.button} aria-label="View Notes" onClick={() => setMobileViewerOpen(true)}>
                    <OpenInBrowser/>
                </IconButton>}
        </div>
    );
};

EditorToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    changeScene: PropTypes.func.isRequired,
    changeAction: PropTypes.func.isRequired,
    editTitle: PropTypes.func.isRequired,
    currentAction: PropTypes.number,
    actions: PropTypes.array,
    isFullscreen: PropTypes.bool,
    setCustomModel: PropTypes.func
};

export default withStyles(styles)(EditorToolbar);
