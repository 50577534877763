import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as ROUTES from './router/routes';
import PrivateRoute from './router/PrivateRoute';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { FirebaseContext } from './auth/firebase/firebaseModule'
import { Header, SideMenu } from './layout';
import Editor from './components/Editor/Editor';
import NotesList from './components/NotesList/NotesList';
import SignIn from './components/SignIn';
import Register from './components/Register';
import Homepage from './pages/Homepage';
import AdminPage from './pages/AdminPage';
import ContextWrapper from './utils/Context';

import './styles/style.scss';

const App = () => {
    const firebase = useContext(FirebaseContext);

    const [user, setUser] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [navBarStatusText, setNavBarStatusText ] = useState(null);

    useEffect(() => {
        firebase.auth.onAuthStateChanged(user => {
            setUser(user);

            if (user) {
                firebase.updateUserData(user);
            }
        });
    }, []);

    return (
        <Router>
            <ContextWrapper>
                <CssBaseline />

                <Header user={user} toggleSidebar={() => setSidebarOpen(!sidebarOpen)}/>

                <SideMenu user={user} open={sidebarOpen}
                    toggleSidebar={() => setSidebarOpen(!sidebarOpen)}/>

                <Switch>
                    <PrivateRoute exact path={[ROUTES.EDITOR, ROUTES.CREATE, ROUTES.EDIT]}
                        component={Editor}
                        user={user}
                        setNavBarStatusText={setNavBarStatusText}/>

                    <PrivateRoute path={[`${ROUTES.EDITOR}/:noteId`, `${ROUTES.EDITOR}/:noteId`, `${ROUTES.EDITOR}/:noteId`]}
                        component={Editor}
                        user={user}
                        setNavBarStatusText={setNavBarStatusText}/>

                    <PrivateRoute path={[`${ROUTES.VIEWER}/:noteId`, `${ROUTES.VIEWER}/:noteId`, `${ROUTES.VIEWER}/:noteId`]}
                        component={Editor}
                        user={user}
                        setNavBarStatusText={setNavBarStatusText}/>

                    <PrivateRoute exact path={ROUTES.NOTES}
                        component={NotesList}
                        user={user}/>

                    <PrivateRoute path={`${ROUTES.NOTES}/:noteCategoryType/:noteCategory`}
                        component={NotesList}
                        user={user}/>

                    <PrivateRoute path={ROUTES.ADMIN} component={AdminPage}/>

                    <Route path={ROUTES.SIGNIN} component={SignIn} />
                    {/*<Route path={ROUTES.REGISTER} component={Register} />*/}


                    <Route exact path="/" component={Homepage}/>
                </Switch>
            </ContextWrapper>
        </Router>
    );
};

const styles = theme => ({
    card: {
        margin: 50,
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

export default withStyles(styles)(App);
