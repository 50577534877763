
const SCENE_IDS = {
    'DEFAULT': "production/maleAdult/male_region_upper_limb_07",
    'AUDITORY': "production/maleAdult/male_system_anatomy_auditory_whole",
    'UPPER_LIMB': "production/maleAdult/male_region_upper_limb_07",
    'LOWER_LIMB': "production/maleAdult/male_region_lower_limb_07",
    'THORAX': "production/maleAdult/male_region_thorax_07",
    'BACK': "production/maleAdult/male_region_back_07",
    'MALE_ABDOMEN': "production/maleAdult/male_region_abdomen_07",
    'FEMALE_ABDOMEN': "production/femaleAdult/female_region_abdomen_07",
    'MALE_PELVIS': "production/maleAdult/male_region_pelvis_07",
    'FEMALE_PELVIS': "production/femaleAdult/female_region_pelvis_07",
    'HEART': "production/maleAdult/heart_v02",
    'BRAIN': "production/maleAdult/brain_v02",
    'HEAD_AND_NECK': "production/maleAdult/male_region_head_07",
    'BREAST': "production/femaleAdult/female_region_breast_07",
    'DEV': "production/maleAdult/heart_v02"
};

export default SCENE_IDS;