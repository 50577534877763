import BIODIGITAL_DISPLAY from './biodigitalUiDisplay';
import SCENE_IDS from './sceneIds';
import CATEGORIES from './categories';

export const API_KEY = 'ade1c97a330684304f79430b5049f4bb0a0da7c4';
export const API_SCRIPT_URL = 'https://developer.biodigital.com/builds/api/2/human-api.min.js';

export {
    BIODIGITAL_DISPLAY,
    SCENE_IDS,
    CATEGORIES
};
