import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import Paper from "@material-ui/core/Paper";
import {Link} from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: `${theme.spacing(1) * 4}px 0 ${theme.spacing(1) * 2}px`,
    },
    listItem: {
        margin: 10,
        padding: 7
    }
});

const NoteListItem = ({
    index,
    title,
    classes,
    deleteNote,
    editLink,
    viewLink
}) => (
    <Paper className={classes.listItem} key={index}>
        <ListItem>
            <ListItemText primary={title}/>

            <ListItemSecondaryAction>
                <IconButton color="primary" className={classes.button}
                    aria-label="Delete" onClick={deleteNote}>
                    <DeleteIcon/>
                </IconButton>

                <Link to={editLink} >
                    <IconButton color="primary" className={classes.button} aria-label="Edit">
                        <EditIcon/>
                    </IconButton>
                </Link>

                <Link to={viewLink} >
                    <IconButton color="primary" className={classes.button} aria-label="View">
                        <LaunchIcon/>
                    </IconButton>
                </Link>
            </ListItemSecondaryAction>
        </ListItem>
    </Paper>
);

NoteListItem.propTypes = {
    classes: PropTypes.object.isRequired,
    index: PropTypes.number,
    title: PropTypes.string,
    viewLink: PropTypes.string,
};

export default withStyles(styles)(NoteListItem);
