import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from "@material-ui/core/Divider";
import * as ROUTES from '../../router/routes'
import { CATEGORIES } from "../../config";
import { FirebaseContext } from "../../auth/firebase/firebaseModule";

const SideMenu = ({ classes, toggleSidebar, user, open }) => {
    const firebase = useContext(FirebaseContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const email = sessionStorage.getItem('email');

        firebase.firestore.collection('admins')
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        setIsAdmin(true);
                    }
                });
            });
    }, []);

    const generateCategoryLinks = (categoryType) => {

        let categoryList = categoryType === "system" ? CATEGORIES.SYSTEM : CATEGORIES.REGION;
        let routesList = categoryType === "system" ? ROUTES.SYSTEMS_NOTES : ROUTES.REGIONS_NOTES;

        const links = _.map(categoryList, (linkText, key) =>
            ({ linkText, linkURL: routesList[key] }));

        return links.map(({ linkText, linkURL }, key) => (
            <Link key={key} to={linkURL} className={classes.menuLink}>
                <ListItem button >
                    <ListItemText primary={linkText} />
                </ListItem>
            </Link>
        ));
    };

    const userLinks = (
        <div className={classes.list}>
            <List>
                <Link to={ROUTES.EDITOR} className={classes.menuLink}>
                    <ListItem button >
                        <ListItemText primary='Create'/>
                    </ListItem>
                </Link>

                <Link to={ROUTES.NOTES} className={classes.menuLink}>
                    <ListItem button >
                        <ListItemText primary='All Notes' />
                    </ListItem>
                </Link>

                {isAdmin && <Link to={ROUTES.ADMIN} className={classes.menuLink}>
                    <ListItem button >
                        <ListItemText primary='Manage'/>
                    </ListItem>
                </Link>}

                <Divider />
                {generateCategoryLinks("region")}
            </List>
        </div>
    );

    const guestLinks = (
        <div className={classes.list}>
            <List>
                <Link to={ROUTES.SIGNIN} className={classes.menuLink}>
                    <ListItem button >
                        <ListItemText primary='Login'/>
                    </ListItem>
                </Link>
                <Link to={ROUTES.REGISTER} className={classes.menuLink}>
                    <ListItem button >
                        <ListItemText primary='Register' />
                    </ListItem>
                </Link>
            </List>
        </div>
    );

    return (
        <Drawer open={open}
            onClick={toggleSidebar}
            onKeyDown={toggleSidebar}>
            <div tabIndex={0} role="button">
                {user ? userLinks : guestLinks }
            </div>
        </Drawer>
    );
};

SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = {
    list: {
        width: 250,
    },
    menuLink: {
      textDecoration: 'none'
    },
    fullList: {
        width: 'auto',
    },
};

export default withStyles(styles)(SideMenu);
