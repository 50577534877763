import React from "react";
import {RotateLoader} from "react-spinners";
import { css } from "@emotion/core";

const override = css`
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
`;

const Loader = ({ loading }) => (
    <RotateLoader
        css={override}
        sizeUnit={"px"}
        size={15}
        color={'#2dce89'}
        loading={loading}
    />
);

export default Loader
