import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from "../../auth/firebase/firebaseModule";
import Note from "../../models/note";
import { PageContext } from '..';

const defaultNoteState = {
    title: "Untitled",
    system: "CARDIOVASCULAR",
    region: "THORAX",
    structure: "ORGAN",
    tags: []
};

const useNotes = (noteSetId) => {
    const dispatch = useContext(PageContext)[1];
    const firebase = useContext(FirebaseContext);

    const [noteSetTitle, setNoteSetTitle] = useState('');
    const [noteSetProperties, setNoteSetProperties] = useState(defaultNoteState);

    const [notes, setNotes] = useState([new Note({ id: firebase.getNotesRef().id })]);
    const [actions, setActions] = useState({});
    const [allActions, setAllActions] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchNotes = async () => {
        setLoading(true);

        try {
            let noteSetRef = firebase.firestore.collection('note_sets').doc(noteSetId);
            let notesSet = await noteSetRef.get();

            const noteTitle = notesSet.data().title;
            setNoteSetTitle(noteTitle);
            dispatch({ type: "SET_NOTE_TITLE", payload: noteTitle });

            let noteSetData = notesSet.data();
            let properties = {...noteSetProperties};

            properties.title = noteSetData.title;

            if (noteSetData.system) properties.system = noteSetData.system;
            if (noteSetData.region) properties.region = noteSetData.region;
            if (noteSetData.structure) properties.structure = noteSetData.structure;
            if (noteSetData.tags) properties.tags = noteSetData.tags;

            setNoteSetProperties(properties);

            let _notes = [], _actions = {}, _allActions = {};
            let _notesQuery = await noteSetRef.collection('notes').orderBy('order').get();

            _notesQuery.forEach(queryDocumentSnapshot => {
                _notes.push(queryDocumentSnapshot.data());
            });

            let actionsForNoteQueryPromises = await _notes.map(note => noteSetRef
                .collection('notes')
                .doc(note.id)
                .collection('actions').get());

            let actionsForNoteQuerySnapshot  = await Promise.all(actionsForNoteQueryPromises);

            actionsForNoteQuerySnapshot.forEach(querySnapshot => {
                querySnapshot.forEach(actionSnapshot => {
                    let _action = actionSnapshot.data();
                    _allActions[_action.id] = _action;
                    _actions[_action.noteId] ? _actions[_action.noteId].push(_action) : _actions[_action.noteId] = [ _action ]
                })
            });

            return {
                notes: _notes,
                actions: _actions,
                allActions: _allActions
            };

        } catch (error) {
            console.error("Error fetching notes", error)
        }
    };

    useEffect(() => {
        if (!noteSetId) {
            return setLoading(false);
        }

        fetchNotes()
            .then(notesData => {
                if (notesData.notes) setNotes(notesData.notes);
                if (notesData.actions) setActions(notesData.actions);
                if (notesData.allActions) setAllActions(notesData.allActions);

                setLoading(false);
            })
            .catch(error => {
                setNotes([new Note({id: firebase.getNotesRef().id})]);
                setLoading(false);

                console.error("Error fetching notes: ", error);
            })
    }, []);

    return [
        notes,
        setNotes,
        actions,
        setActions,
        allActions,
        setAllActions,
        loading,
        noteSetProperties,
        setNoteSetProperties
    ];
};

export default useNotes;
