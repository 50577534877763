import React, { useReducer, createContext } from "react";
import { defaultContext } from "./defaultContext";
import reducer from "./reducer";

export const PageContext = createContext();

const ContextWrapper = ({ children }) => {
  const contextValue = useReducer(reducer, defaultContext);

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export default ContextWrapper;
