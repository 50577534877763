import React from 'react'
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    annotationsCanvas: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
});

const AnnotationsCanvas = ({ classes, storeAnnotationWorldPosition, annotationsOn }) => {
    const handleClick = event => {
        const canvas = document.getElementById('annotationsCanvas');
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const canvasPosition = {
            x: x,
            y: y
        };

        storeAnnotationWorldPosition(canvasPosition);
    };

    if (!annotationsOn) {
        return null;
    }

    return (
        <canvas id="annotationsCanvas" className={classes.annotationsCanvas} onClick={handleClick}/>
    );
};

AnnotationsCanvas.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AnnotationsCanvas);
