import React from 'react';
import ActionButtons from "./ActionButtons";
import ActionList from "./ActionList";
import PropTypes from "prop-types";
import ActionsChip from "./ActionsChip";

const Actions = ({
    currentAction,
    actions,
    changeAction,
    setActionEditState
}) => (
    <>
        <ActionsChip currentAction={currentAction} numActions={actions.length}/>
        <ActionButtons changeAction={changeAction}/>
        <ActionList
            actions={actions}
            changeAction={changeAction}
            setActionEditState={setActionEditState}
        />
    </>
);

Actions.propTypes = {
    changeAction: PropTypes.func.isRequired,
    currentAction: PropTypes.number,
    actions: PropTypes.array,
    setOpenActionEditDialog: PropTypes.func,
};

export default Actions;
