import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    toolbar: {
        margin: theme.spacing(1)
    }
});

const CustomToolbar = props => {

    const { classes } = props;

    return (
        <div id="toolbar" className={classes.toolbar}>
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
                <option value="" />
                <option value="1"/>
                <option value="2"/>
                <option value="3"/>
                <option value="4"/>
                <option value="5"/>
            </select>
            <button className="ql-bold"/>
            <button className="ql-italic"/>
            <button className="ql-underline"/>
            <button className="ql-list" value={"ordered"}></button>
            <button className="ql-list" value={"bullet"}></button>
            <button className="ql-insertAction">
                <AddIcon/>
            </button>
        </div>
    )
};

export default withStyles(styles)(CustomToolbar);
