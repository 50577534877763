import config from '../../config/firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.app = app;
        this.auth = app.auth();
        this.firestore = app.firestore();
    }

    updateUserData = user => {
        this._addUserDatabase(user);

        this.firestore.collection('users').doc(user.uid).set({
            login_history: this
                .app
                .firestore
                .FieldValue
                .arrayUnion(Date.now())
        }, {merge: true});
    };

    _addUserDatabase = user => {
        const store = this.firestore;

        let _user = {};
        _user.uid = user.uid;
        _user.email = user.email;

        store
            .collection('users')
            .doc(_user.uid)
            .get()
            .then((userSnap) => {
                if (!userSnap.data().email) {
                    store
                        .collection('users')
                        .doc(_user.uid)
                        .set(_user, {merge: true});
                }
            })
    };

    createUserWithEmailAndPassword = (email, password, onSuccess, onError) => {
        this.auth.createUserWithEmailAndPassword(email, password)
            .then(authUser => {
               this._addUserDatabase(authUser.user);

                if(onSuccess) onSuccess();
            })
            .catch(error =>{
                if (onError) onError(error);
            });
    };

    signInWithEmailAndPassword = (email, password, onSuccess, onError) => {
        this.auth.signInWithEmailAndPassword(email, password)
            .then(user => {
                sessionStorage.setItem('auth', true);
                sessionStorage.setItem('email', email);

                this._addUserDatabase(user.user);

                if (onSuccess) onSuccess();
            })
            .catch(error => {
                if (onError) onError(error);
            });
    };

    signOut = () => {
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('email');

        return this.auth.signOut();
    };

    passwordReset = email => this.auth.sendPasswordResetEmail(email);

    passwordUpdate = password => this.auth.currentUser.updatePassword(password);

    // *** Firestore API ***

    getNoteSetRef = (noteId) => {
        let ref = this.firestore.collection('note_sets');
        let doc;

        noteId ? doc = ref.doc(`${noteId}`) : doc = ref.doc();

        return doc;
    };

    getNotesRef = () => {
        let doc = this.firestore.collection('note_sets').doc().collection('notes').doc();

        return doc;
    };

    getUniqueFirestoreId = () => this.firestore.collection('note_sets').doc().id;

    deleteNoteSet = (id, callback) => this.firestore.collection('note_sets').doc(id)
        .delete()
        .then(() => {
            if (callback) {
                callback();
            }
        });
}

export default Firebase;
