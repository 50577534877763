import React from 'react';
import _ from 'lodash';
import { Route, Redirect } from "react-router-dom";
import * as ROUTES from '../../router/routes';

const PrivateRoute = ({
    component: Component,
    setNavBarStatusText,
    ...rest
}) => (
    <Route {...rest}
        render={(props) => {
            const isAuth = sessionStorage.getItem('auth');

            if (!isAuth) {
                return (
                    <Redirect to={{
                        pathname: ROUTES.SIGNIN,
                        state: { from: props.location }
                    }}/>
                );
            }

            return (
                <Component {...props}
                    setNavBarStatusText={setNavBarStatusText}/>
            );
        }}
    />
);

export default PrivateRoute;
