import { SCENE_IDS } from '../config';

export default class Note {
    constructor(noteData = {
        id: '',
        order: 0,
        title: '',
        content: '',
        actions: []
    }) {
        this.id = noteData.id || '';
        this.order = noteData.order || 0;
        this.title = noteData.title || '';
        this.content = noteData.content || '';
        this.scene = noteData.scene || SCENE_IDS.DEV
    }
};
