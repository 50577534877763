export const CREATE = '/create';
export const VIEWER = '/viewer';
export const EDIT = '/edit';
export const EDITOR = '/editor';
export const NOTES = '/notes';
export const SIGNIN = '/signin';
export const REGISTER = '/register';
export const ADMIN = '/manage';

export const SYSTEMS_NOTES = {
    "MUSCULOSKELETAL": "/notes/system/musculoskeletal",
    "CARDIOVASCULAR": "/notes/system/cardiovascular",
    "NEUROANATOMY": "/notes/system/neuroanatomy",
    "RESPIRATORY": "/notes/system/respiratory",
    "DIGESTIVE": "/notes/system/digestive",
    "URINARY": "/notes/system/urinary",
    "REPRODUCTIVE": "/notes/system/reproductive"
};

export const REGIONS_NOTES = {
    "UPPER_LIMB": "/notes/region/upper-limb",
    "LOWER_LIMB": "/notes/region/lower-limb",
    "HEAD": "/notes/region/head",
    "NECK": "/notes/region/neck",
    "BACK": "/notes/region/back",
    "THORAX": "/notes/region/thorax",
    "ABDOMEN": "/notes/region/abdomen",
    "PELVIS": "/notes/region/pelvis",
};
