import React from 'react'
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";

const ActionsChip = ({
    currentAction,
    numActions,
    style
}) => (
    <Chip label={currentAction ? `Label ${currentAction}/${numActions}` : `No labels` } variant="outlined" style={style}/>
);

export default ActionsChip;
