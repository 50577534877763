import React from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";

const ModalActionEdit = ({
    open,
    actionEditState,
    setActionEditState,
    action,
    updateActionForNote
}) => {
    const isOpen = !_.isEmpty(action) && open;
    const actionTitle = _.get(action, 'title');

    return (
        <Dialog
            open={isOpen}
            onClose={() => setActionEditState({...actionEditState, open: false})}
            aria-labelledby="form-dialog-title"
            fullWidth={true}>

            <DialogTitle id="form-dialog-title">Edit Action</DialogTitle>

            <DialogContent>
                <TextField
                    fullWidth={true}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Action Name"
                    type="text"
                    defaultValue={actionTitle}
                    onChange={event => updateActionForNote(action.id, "title", event.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setActionEditState({...actionEditState, open: false})}
                    color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => setActionEditState({...actionEditState, open: false})}
                    color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ModalActionEdit.propTypes = {
    open: PropTypes.bool,
    actionEditState: PropTypes.object,
    setActionEditState: PropTypes.func,
    actions: PropTypes.array
};

export default ModalActionEdit
