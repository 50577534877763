export default class Action {
    constructor(actionData = {
        labels: [],
        title: "",
        id: "",
        noteId: "",
        noteSetId: "",
        modes: [],
        camera: {},
        objectsShown: {},
        objectsSelected: {},
        order: 0,
        additionalSceneData: {} // additional data from BioDigital SceneState
    }) {
        this.id = actionData.id || "";
        this.order = actionData.order || 0;
        this.noteId = actionData.noteId || "";
        this.noteSetId = actionData.noteSetId || "";
        this.title = actionData.title || "";
        this.labels = actionData.labels || [];
        this.modes = actionData.modes || [];
        this.camera = actionData.camera || {};
        this.objectsShown = actionData.objectsShown || {};
        this.objectsSelected = actionData.objectsSelected || {};
        this.additionalSceneData = actionData.additionalSceneData || {};
        this.scene = actionData.scene || "";
    }
};
