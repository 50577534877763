import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BarLoader from "react-spinners/BarLoader";

const StatusBar = ({ classes, appStatus }) => (
    <div style={{"textAlign": "center"}}>
        <BarLoader
            width="auto"
            color={"#123abc"}
            loading={appStatus.isSaving}
        />
        <span className={classes.status}>{appStatus.message}</span>
    </div>
);

StatusBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    button: {
        margin: '1px',
    },
    status: {
        fontSize: 'small'
    }
});

export default withStyles(styles)(StatusBar);
