import _ from 'lodash';
import { isMobile as detectIsMobile } from 'react-device-detect';
import { PageContext } from "./Context";
import useNotes from './Hooks';

export { PageContext, useNotes };

export const _compare = (a, b) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
};

export const isMobile = detectIsMobile;

export const isViewMode = window.location.pathname.includes('viewer');
