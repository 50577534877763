import React, { useContext} from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import * as ROUTES from '../../router/routes';
import FirebaseContext  from "../../auth/firebase/firebaseContext";

const Header = ({
    classes,
    toggleSidebar,
    history
}) => {
    const firebase = useContext(FirebaseContext);

    const logout = () => {
        firebase
            .signOut()
            .then(() => {
                history.push(ROUTES.SIGNIN);
            });
    };

    const isAuthenticated = sessionStorage.getItem('auth');
    const email = sessionStorage.getItem('email');

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar style={{maxHeight: "75px", overflow: "hidden"}}>

                    <IconButton onClick={toggleSidebar} className={classes.menuButton} color="inherit" aria-label="Menu">
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        <Link to='/' style={{ 'textDecoration': 'none', 'color':'white' }}>
                            3D Anatomy
                        </Link>
                    </Typography>

                    {isAuthenticated && <h5 className={classes.headerText}>{email}</h5>}

                    {isAuthenticated ?
                        <Button color="inherit" onClick={logout}>Logout</Button> :
                        <Button color="inherit" component={Link} to={ROUTES.SIGNIN}>Login</Button>}

                </Toolbar>
            </AppBar>
        </div>
    );
};

const styles = {
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    loginLink: {
        textDecoration: 'none',
    },
    headerText: {
        marginRight: 15
    }
};

export default withStyles(styles)(compose(withRouter)(Header));
