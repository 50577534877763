import React, {useState} from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = ({
    initialValue = '',
    open,
    onCancel,
    onConfirm,
    onConfirmText,
    hideTextField,
    label,
    content,
    title,
    textValue
}) => {
    const [text, setText] = useState(initialValue);

    return (
        <Dialog open={open} onClose={onCancel}
            aria-labelledby="form-dialog-title">

            <DialogTitle id="form-dialog-title">{title}</DialogTitle>

            <DialogContent>
                {content &&
                    <DialogContentText>
                        {content}
                    </DialogContentText>}

                {!hideTextField &&
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={label}
                        type="text"
                        defaultValue={textValue}
                        onChange={event => setText(event.target.value)}
                        fullWidth
                    />}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => onCancel(initialValue)}
                    color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => onConfirm(text)}
                    color="primary">
                    {onConfirmText ? onConfirmText : "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

FormDialog.propTypes = {
    initialValue: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    textValue: PropTypes.string,
    label: PropTypes.string,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onConfirmText: PropTypes.string
};

export default FormDialog;
