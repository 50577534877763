import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { Link } from "react-router-dom";
import * as ROUTES from '../router/routes'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { FirebaseContext } from "../auth/firebase/firebaseModule";
import { compose } from 'recompose';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: 'rgba(140, 236, 138, 0.8)',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
});

const SignInBase = (props) => {
    const firebase = useContext(FirebaseContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const onSubmit = (event) => {
        firebase.signInWithEmailAndPassword(email, password, () => {
            props.history.push(ROUTES.CREATE);
        }, (error) => {
            setError(error);
        });

        event.preventDefault();
    };

    const { classes } =  props;

    return (
        <div className={classes.main}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">Sign in</Typography>

                <form className={classes.form} onSubmit={onSubmit}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input id="email" name="email" autoComplete="email" onChange={(event) => setEmail(event.target.value)} autoFocus />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input name="password" type="password" id="password" onChange={(event) => setPassword(event.target.value)} autoComplete="current-password" />
                    </FormControl>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Sign in
                    </Button>

                    <Typography align='right' variant='body1' style={{ marginTop: 15, paddingRight: 5 }}>
                        <Link to={ROUTES.REGISTER}>Register</Link>
                    </Typography>

                    {error &&
                        <Typography align='center' variant='body2' color='error'>
                            {error.message}
                        </Typography>}
                </form>
            </Paper>
        </div>
    );
}

SignInBase.propTypes = {
    classes: PropTypes.object.isRequired,
};

const SignIn = compose(withRouter)(SignInBase);

export default withStyles(styles)(SignIn);
