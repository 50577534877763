import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from "../../auth/firebase/firebaseModule";
import { Paper, Button, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

const AdminPage = ({ classes }) => {
    const firebase = useContext(FirebaseContext);
    const [adminList, setAdminList] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const email = sessionStorage.getItem('email');

        firebase.firestore.collection('admins')
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        setIsAdmin(true);
                    } else {
                        window.location.replace('/');
                    }
                });
            });
    }, []);

    useEffect(() => {
        const admins = [];

        firebase.firestore.collection('admins')
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    admins.push(doc.data());
                });
            })
            .finally(() => {
                setAdminList(admins);
            });
    }, []);

    const addUser = () => {
        firebase.firestore.collection('admins')
            .add({
                email: userEmail
            })
            .then(() => {
                window.location.reload();
            });
    };

    const removeUser = (email) => {
        let confirm = window.confirm('Are you sure ?');

        if (!confirm) { return; }

        firebase.firestore.collection('admins')
            .where('email', '==', email)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach((doc) => {
                    if (!doc.exists) { return; }

                    firebase.firestore.collection('admins')
                        .doc(doc.id)
                        .delete()
                        .then(() => {
                            window.location.reload();
                        });
                });
            });
    }

    if (!isAdmin) {
        return null;
    }

    return (
        <Paper className={classes.paper}>
            <h4>Admin Roles by Email</h4>

            <div className={classes.inputs}>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="User's email"
                    type="text"
                    onChange={event => setUserEmail(event.target.value)}/>
                <Button onClick={addUser} color="primary" className={classes.button}>
                    Add
                </Button>
            </div>
            <br/><br/>
            <div>
                <h5>Admins:</h5>
                {adminList.map(({ email }) =>
                    (<li key={email}>
                        <span>{email}</span>
                        <button onClick={() => removeUser(email)} className={classes.button}>&times;</button>
                    </li>))}
            </div>

        </Paper>
    );
}

const styles = theme => ({
    paper: {
        margin: theme.spacing(8),
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    inputs: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        marginLeft: 10,
        border: 0,
        cursor: 'pointer'
    }
});

export default withStyles(styles)(AdminPage);
