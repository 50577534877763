import React from 'react';

const Homepage = () => (
    <div className="homepage">
        <div className="img-logo"/>
        <div className="welcome-msg">Welcome </div>
        <a href="/create">Create new Note</a>
    </div>
);

export default Homepage;
