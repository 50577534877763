import React from 'react';
import parse, {domToReact} from 'html-react-parser';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import NotesControls from "./NotesControls";
import NotesChip from "./NotesChip";
import { isMobile } from '../../utils';

const notesChipStyle = {
    margin: '8px',
    height: 'auto',
    fontSize: '0.65rem'
};

const NotesViewer = ({
    currentNote,
    changeAction,
    changeNote,
    numNotes,
    isPortrait
}) => {
    const calcViewerHeight = () => {
        let windowHeight = window.innerHeight;
        return windowHeight-135;
    };

    const paperStyle = () => {
        if (isMobile && isPortrait) {
            return {
                margin: "5px",
                height: "400px",
                overflowY: "scroll",
                background: "rgba(255,255,255,0.9)"
            }
        } else if (isMobile && !isPortrait) {
            return {
                margin: "5px",
                height: "100%",
                overflowY: "scroll",
                background: "rgba(255,255,255,0.9)"
            }
        } else {
            return {
                margin: "5px",
                height: calcViewerHeight(),
                overflowY: "auto",
            }
        }
    };

    return (
        <Paper style={paperStyle()}>
            <Grid container spacing={0}>
                <Grid item xs={3}>
                    <NotesChip order={currentNote.order + 1} numNotes={numNotes} style={notesChipStyle}/>
                </Grid>
                <Grid item xs={6}>
                    <div style={{textAlign: "center"}}>
                        <h2>{currentNote.title}</h2>
                    </div>
                </Grid>
                <Grid item xs={3} style={{textAlign: "right"}}>
                    <NotesControls
                        style={{position: 'fixed', bottom: 0, right: 0}}
                        isPopout={true}
                        changeNote={changeNote}
                    />
                </Grid>
                <Grid item xs={12} style={{borderTop: "1px solid rgb(224, 224, 224"}}>
                    <div style={{padding: "0 15px"}}>
                        {parse(currentNote.content, {
                            replace: ({attribs, children}) => {

                                if (!attribs) return;

                                if (attribs.class == "action-item") {
                                    return (
                                        <span className="action-item" onClick={() => changeAction(null, attribs["data-key"])}>
                                            {domToReact(children)}
                                        </span>
                                    );
                                }
                            }
                        })}
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default NotesViewer;
